import React from 'react'
import { Box, Typography, TypographyProps } from '@material-ui/core'

import useSiteMetadata from 'hooks/useSiteMetadata'

export const AppVersion = (props: TypographyProps<any>) => {
  const siteMetadata = useSiteMetadata()
  return (
    <Typography {...props}>
      <code>v{siteMetadata.package.version}<Box px={1 / 3} color="text.secondary" component="span">@{siteMetadata.shortHash}</Box></code>{siteMetadata.branch && (<span>&nbsp;(<code>{siteMetadata.branch}</code>)</span>)}
    </Typography>
  )
}
