import React from 'react'

import { UserDTO } from 'api'
import { defaultIApiState, IApiState } from 'hooks/useApi'

export type CurrentUser = UserDTO & {
  isImpersonated?: boolean
}

export type CurrentUserContext = {
  currentUser?: CurrentUser | undefined
  clearCurrentUser: () => void
  resetCurrentUser: () => void
  fetchState: IApiState<UserDTO>
  setIsImpersonated: (isImpersonated: boolean) => void,
}

export const defaultContext: CurrentUserContext = {
  currentUser: undefined,
  clearCurrentUser: () => {},
  resetCurrentUser: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsImpersonated: (_isImpersonated: boolean) => { console.error('NOT IMPLEMENTED') },
  fetchState: defaultIApiState,
}
export default React.createContext<CurrentUserContext>(defaultContext)
