import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import * as Sentry from '@sentry/browser'
import { relocateToSignIn } from 'contexts/api/RelocateToSignIn'

import { UserDTO } from 'api'
import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'

import context, { CurrentUser } from './context'

const { Provider } = context

export interface Props {
  children: ReactNode
  blocking?: boolean
}

export const CURRENT_USER_STORAGE_KEY = 'currentUser'

export const CurrentUserProvider = (props: Props) => {
  const {
    children,
    blocking,
  } = props
  const [currentUser, setCurrentUser] = useLocalStorage<CurrentUser | false | undefined>(CURRENT_USER_STORAGE_KEY)
  const [isImpersonated, setIsImpersonated] = useState<boolean | undefined>()

  useEffect(() => {
    if(currentUser && !currentUser.isImpersonated) {
      if(currentUser.identifierHash && window.FS) {
        window.FS.identify(currentUser.identifierHash, {
          displayName: `${currentUser.firstName} ${currentUser.lastName}`,
          email: currentUser.email,
        })
      }
      Sentry.setUser({
        id: currentUser.id ? currentUser.id.toString() : undefined,
        username: currentUser.email,
      })
    }
  }, [currentUser])

  const clearCurrentUser = useCallback(() => {
    setCurrentUser(false)
  }, [setCurrentUser])

  const resetCurrentUser = useCallback(() => {
    setCurrentUser(undefined)
  }, [setCurrentUser])

  const fetchState = useApi<UserDTO>((typeof currentUser === 'undefined') ? 'v1/users/me' : undefined, undefined, { requestOptions: { cache: 'no-cache' } })
  const {
    data: fetchedUser,
    error,
    isLoading,
  } = fetchState

  useEffect(() => {
    if(fetchedUser) {
      setCurrentUser({
        ...fetchedUser,
        isImpersonated,
      })
    }
  }, [fetchedUser, isImpersonated, setCurrentUser])

  useEffect(() => {
    if(error) {
      if(error.statusCode && error.statusCode === 401) {
        setCurrentUser(false)
        relocateToSignIn()
      } else {
        throw error
      }
    }
  }, [error, setCurrentUser])

  if(blocking && isLoading && !currentUser) {
    return (
      <PaddedFlexCentered>
        <Spinner />
      </PaddedFlexCentered>
    )
  }

  return (
    <Provider
      value={{
        currentUser: (currentUser === false) ? undefined : currentUser,
        clearCurrentUser,
        resetCurrentUser,
        fetchState,
        setIsImpersonated,
      }}
    >
      {children}
    </Provider>
  )
}

export default CurrentUserProvider
