import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { MdPlayArrow, MdSignalWifiOff } from 'react-icons/md'
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Container, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { ErrorWithStatusCode } from 'contexts/api/FetchError'
import { currentUserContext } from 'contexts/CurrentUser'
import { networkStateContext } from 'contexts/NetworkState'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Link } from 'gatsby'
import * as Yup from 'yup'

import { LoginDTO, LoginResultDTO } from 'api'
import { AppVersion } from 'components/AppVersion'
import useApi from 'hooks/useApi'
import { useTimeout } from 'hooks/useTimeout'

import FullLogo from '!!svg-react-loader!logo/AssetLink.svg' // eslint-disable-line

type StyleProps = {
  success: boolean
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: '100%',
  },
  paper: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  autofillHack: {
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: (props: StyleProps) => { return props.success ? green[500] : undefined },
    '&:hover': {
      backgroundColor: (props: StyleProps) => { return props.success ? green[700] : undefined },
    },
  },
  submitWrapper: {
    position: 'relative',
  },
  submitProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  offlineIcon: {
    color: (props: StyleProps) => props.success ? green[800] : undefined,
  },
  offlineNoticeText: {
    color: (props: StyleProps) => props.success ? green[800] : undefined,
  },
  learnMoreCard: {
    flex: '0 0 auto',
    margin: theme.spacing(3, 0, 3),
  },
  learnMoreCardActions: {
    flexDirection: 'row',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  version: {
    flex: 0,
    textAlign: 'center',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    opacity: 0.5,
  },
}))

export const loginFormSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
})

export interface LoginFormValues {
  email: string
  password: string
}

const emailRE = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

export const SignIn = () => {
  const { online } = useContext(networkStateContext)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<LoginDTO>()
  const [postLoginUrl, setPostLoginUrl] = useState<URL>()
  const [doNavigate, setDoNavigate] = useState<boolean>(false)
  const {
    currentUser,
    resetCurrentUser,
    clearCurrentUser,
    fetchState: currentUserFetchState,
    setIsImpersonated,
  } = useContext(currentUserContext)

  // Detect if user is impersonating another user (admin)
  const isImpersonating = useMemo<boolean | undefined>(() => {
    if(credentials && credentials.username) {
      const arr = credentials.username.split('#')
      if(arr.length < 2) {
        return false
      }
      return arr.every(email => emailRE.test(email))
    }
  }, [credentials])

  useEffect(() => {
    if(typeof isImpersonating === 'boolean') {
      setIsImpersonated(isImpersonating)
    }
  }, [isImpersonating, setIsImpersonated])

  const loginFetchState = useApi<LoginResultDTO, LoginDTO>(credentials ? 'v1/authentication/signin' : undefined, undefined, {
    requestOptions: {
      method: 'POST',
      body: credentials ? JSON.stringify(credentials) : undefined,
    },
    dependencies: [credentials],
  })
  const pingFetchState = useApi('v1/ping')
  const isLoading = currentUserFetchState.isLoading || pingFetchState.isLoading || loginFetchState.isLoading

  useEffect(() => {
    if(online) {
      resetCurrentUser()
    }
  }, [online, resetCurrentUser])

  /**
   * Capture the "after" URL query param for post-login navigation
   */
  useEffect(() => {
    const url = new URL(window.location.href)
    const afterRaw = url.searchParams.get('after')
    const after = afterRaw ? decodeURIComponent(afterRaw) : '/app/'
    url.searchParams.delete('after')
    const skip = /(logout|signin)/i
    if(!skip.test(after)) {
      url.pathname = after
    }
    setPostLoginUrl(url)
  }, [])

  /**
   * Handle bad logins
   */
  useEffect(() => {
    if(loginFetchState.error && loginFetchState.statusCode && loginFetchState.statusCode > 403) {
      throw loginFetchState.error
    }
    if(loginFetchState.statusCode === 200) {
      resetCurrentUser()
    }
  }, [loginFetchState.error, loginFetchState.statusCode, resetCurrentUser])

  /**
   * Determine when to start navigation
   */
  const startDoNavigateTimeout = useMemo<boolean>(() => {
    if(online && currentUser) {
      return true
    }
    return Boolean(currentUser || currentUserFetchState.statusCode === 200)
  }, [currentUser, currentUserFetchState.statusCode, online])

  /**
   * Start a timer to do navigation so we can briefly show a success state to the user before transitioning.
   */
  useTimeout(() => {
    setDoNavigate(true)
  }, startDoNavigateTimeout ? 500 : null, [currentUserFetchState])

  /**
   * Actually do the window navigation
   */
  useEffect(() => {
    if(doNavigate && postLoginUrl) {
      const url = new URL(window.location.href)
      if(url.searchParams.has('norel') || url.searchParams.has('norelocate')) {
        // eslint-disable-next-line no-console
        console.log('No-relocate specified in url parameters. Skipping relocation.')
      } else {
        window.location.href = postLoginUrl.href
      }
    }
  }, [doNavigate, postLoginUrl])

  useEffect(() => {
    if(!pingFetchState.error && pingFetchState.data && pingFetchState.data !== 'pong') {
      const err: ErrorWithStatusCode = new Error('API Unavailable')
      err.statusCode = 503
      err.name = 'NetworkError'
      throw err
    }
  }, [pingFetchState.data, pingFetchState.error])

  const classes = useStyles({ success: startDoNavigateTimeout })

  const handleSubmit = (formVals: LoginFormValues) => {
    clearCurrentUser()
    setCredentials({ username: formVals.email, password: formVals.password })
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleShowPasswordMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const $buttonContent: ReactNode = (() => {
    if(currentUser && currentUser.firstName) {
      return `Welcome, ${currentUser.firstName}`
    }
    return 'Sign In'
  })()

  const $offlineNotice = (
    <Paper elevation={1} className={classes.paper}>
      <Box px={4} py={3}>
        <Box fontSize="5vh" role="img" component="div" textAlign="center">
          <MdSignalWifiOff className={classes.offlineIcon} />
        </Box>
        <Typography variant="body1" align="center" className={classes.offlineNoticeText}>
          {startDoNavigateTimeout && currentUser ? `Welcome back, ${currentUser.firstName}` : 'You must be online to sign in.'}
        </Typography>
      </Box>
    </Paper>
  )

  const $form = (
    <Formik
      initialValues={{ email: '', password: '' } as LoginFormValues}
      validationSchema={loginFormSchema}
      onSubmit={handleSubmit}
      render={(formikBag: FormikProps<LoginFormValues>) => {
        const {
          isValid,
          errors,
          touched,
        } = formikBag
        return (
          <Form noValidate>
            <Field
              name="email"
              render={({ field }: FieldProps) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  error={!!(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ className: classes.autofillHack }}
                  {...field}
                />
              )}
            />
            <Field
              name="password"
              render={({ field }: FieldProps) => (
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  error={loginFetchState.isError || !!(errors.password && touched.password)}
                  helperText={loginFetchState.isError ? 'Invalid credentials' : (touched.password && errors.password)}
                  autoComplete="current-password"
                  disabled={isLoading}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ className: classes.autofillHack }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={toggleShowPassword}
                          onMouseDown={handleShowPasswordMouseDown}
                        >
                          {field.value ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
            <div className={classes.submitWrapper}>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!isValid || isLoading}
              >
                {$buttonContent}
                {isLoading && <CircularProgress size={24} className={classes.submitProgress} />}
              </Button>
            </div>
            <Grid container>
              <Grid item xs>
                <Link to="/forgotpassword">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    />
  )

  return (
    <Container maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <Box className={classes.avatar} pb={5}>
          <FullLogo style={{ width: '100%' }} />
        </Box>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {online ? $form : $offlineNotice}
      </div>
      <Card className={classes.learnMoreCard}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            What is AssetLink?
          </Typography>
          <Typography variant="body2" component="p">
            Watch a short video to learn how AssetLink can help speed up your oil analysis program.
          </Typography>
          <CardActions className={classes.learnMoreCardActions}>
            <Button
              component="a"
              href="https://www.youtube.com/watch?v=OPrODlOclBM"
              size="small"
              endIcon={<MdPlayArrow />}
            >
              Watch Video
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      <AppVersion className={classes.version} component="div" variant="subtitle2" />
    </Container>
  )
}

export default SignIn
