import React from 'react'
import { CurrentUserProvider } from 'contexts/CurrentUser'

import SignIn from 'components/SignIn'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

export const SignInPage = () => {
  return (
    <CurrentUserProvider>
      <SiteHeadMetadata title="Sign In" />
      <SignIn />
    </CurrentUserProvider>
  )
}

export default SignInPage
